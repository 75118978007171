import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { LinkText } from "../../templates/LinkText";

const Collection = () => {
  const { t } = useTranslation();
  return (
    <section className="services-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <h3>{t("Service - Collection - Title - 1")}</h3>
              <p>{t("Service - Collection - Text - 1")}</p>
              <p>{t("Service - Collection - Text - 2")}</p>

              <h3>{t("Service - Collection - Title - 2")}</h3>

              <p>
                <Trans
                  i18nKey="Service - Collection - Text - 3"
                  components={{
                    link_emobility: (
                      <LinkText to="/emobility" title="Electro Mobility" />
                    ),
                  }}
                />
              </p>

              <h3>{t("Service - Collection - Title - 3")}</h3>
              <p>{t("Service - Collection - Text - 4")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Collection;
