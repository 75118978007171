import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Collection from "../components/Services/Collection";
import { graphql } from "gatsby";
import { Seo } from "../components/App/SEO";

const CollectionPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("Inkasso")}
        homePageText="Home"
        homePageUrl="/"
        activePageText={t("Leistungen")}
      />
      <Collection />
      <Footer />
    </Layout>
  );
};

export default CollectionPage;

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <Seo
    title="Inkasso als Dienstleistung"
    description="Blockstrom übernimmt das Inkasso der Stromkosten. Die Rechnungen werden direkt an die Verbraucher gesendet."
  />
);
